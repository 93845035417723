





















import Vue, { PropType } from 'vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

export default Vue.extend({
  name: 'CategoriesList',

  props: {
    categories: {
      type: Array as PropType<IArticleCategory[]>,
      default: () => [],
    },

    isBreadcrumbs: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    lastBlocked: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isCircular (url: string): boolean {
      const path = this.$route?.path || '';

      // Все пути в здоровье включают в себя префикс главной страницы
      // Делаем доп проверку на этот префикс чтобы крошка главной страницы была кликабельной
      return url === path;
    },
    getUrl (url: string) {
      return getSlugFromUrl(url);
    },
    blockedLink (url: string, idx: number) {
      if (this.lastBlocked) {
        const isLastBreadCrumb = this.categories.length - 1 === idx;
        return isLastBreadCrumb;
      }
      return this.isCircular(url);
    },
  },
});
